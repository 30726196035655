import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import Theme from '../../../styles/Theme';
import { Table } from '../../atoms/Table/Table';
import Loading from '../../atoms/Loading/Loading';
import Container from '../../atoms/Container/Container';
import Typography from '../../atoms/Typography/Typography';
import TableSkeleton from '../../atoms/Table/TableSkeleton';
import { ButtonBack } from '../../atoms/Button/StyledButton';
import ParamsModel from '../../models/PaginationModel';
import FilterForm from '../../organisms/FilterForm/FilterForm';
import FilterFormSkeleton from '../../organisms/FilterForm/FilterFormSkeleton';
import { CategoryReponse } from '../../../interfaces/Category.d';
import DetailResultsAdapter from '../../../adapters/DetailResultsAdapter';
import FilterModel from '../../molecules/Filter/FilterModel';
import CardDataExport from '../../molecules/Cards/CardDataExport';
import useExportStore from '../../../store/exportStore';
import GenericError from '../Error/GenericError';
import useYear from '../../../hooks/useYear';
import useRemoveUndefinedProperties from '../../../hooks/useRemoveUndefinedProperties';
import { createGraphQLClient } from '../../../graphql/graphqlClient';
import GET_RESULTS_BY_CODE_GQL from '../../../graphql/queries/getResultsByCodeGql';
import GET_MY_TEAM_DATA_FILTERS_GQL from '../../../graphql/queries/getMyTeamDataFiltersGql';
import GET_PLAN_RESULTS_CONCRETE_BY_CODE_GQL from '../../../graphql/queries/getPlanResultsConcreteByCodeGql';

export default function ConventionDetailResults() {
  const { id } = useParams<string>();
  const { year } = useYear();
  const graphQLClient = createGraphQLClient();
  const queryClient = useQueryClient();
  const categoryResponse = queryClient.getQueryState<CategoryReponse>(['UserCategory']);
  const navigate = useNavigate();

  const [globalFilter, setGlobalFilter] = useState<FilterModel>(() => {
    const filter = new FilterModel();
    if (categoryResponse?.data?.userCategory) {
      filter.dgt = categoryResponse.data.userCategory.dgt || '';
      filter.dt = categoryResponse.data.userCategory.dt || '';
      filter.od = categoryResponse.data.userCategory.od || '';
      filter.selectedScope = categoryResponse.data.userCategory.scope || '';
    }
    return filter;
  });

  const [globalParams, setGlobalParams] = useState<ParamsModel>(new ParamsModel());

  useEffect(() => {
    if (categoryResponse?.data?.userCategory) {
      setGlobalFilter((prevFilter) => ({
        ...prevFilter,
        dgt: categoryResponse?.data?.userCategory?.dgt || '',
        dt: categoryResponse?.data?.userCategory?.dt || '',
        od: categoryResponse?.data?.userCategory?.od || '',
        selectedScope: categoryResponse?.data?.userCategory?.scope || '',
      }));
    }
  }, [categoryResponse]);

  const params: any = {
    code: id,
    selectedScope: globalFilter.selectedScope || undefined,
    category: globalFilter.category || undefined,
    dgt: globalFilter.dgt || undefined,
    dt: globalFilter.dt || undefined,
    od: globalFilter.od || undefined,
    firstName: globalFilter.firstName || undefined,
    lastName: globalFilter.lastName || undefined,
    nif: globalFilter.nif || undefined,
    group: globalFilter.group || undefined,
    myTeamData: true,
    year,
    status: globalFilter.status || undefined,
    order: globalParams?.order || 'dgt',
    orderType: globalParams?.orderType || 'desc',
  };

  const conventionByCodeParams = {
    code: id,
    myTeam: true,
    dgt: globalFilter.dgt || undefined,
    dt: globalFilter.dt || undefined,
    od: globalFilter.od || undefined,
    numberElements: globalParams?.numberElements || 10,
    numberPage: globalParams?.numberPage || 1,
    order: globalParams?.order || 'dgt',
    orderType: globalParams?.orderType || 'desc',
  };

  const {
    data: dataPlanByCode,
    error: errorPlanByCode,
  } = useQuery(
    ['ConventionByCode', conventionByCodeParams],
    async () => (graphQLClient && graphQLClient.request(
      GET_PLAN_RESULTS_CONCRETE_BY_CODE_GQL,
      conventionByCodeParams,
    )),
    {
      staleTime: Infinity,
    },
  );

  const conventionParams = {
    ...params,
    numberElements: globalParams?.numberElements || 10,
    numberPage: globalParams?.numberPage || 1,
  };
  useRemoveUndefinedProperties(conventionParams);

  const {
    data: dataResultsByCode,
    error: errorResultsByCode,
    refetch: refetchResultsByCode,
    isFetching: isFetchingResultsByCode,
    status: statusResultsByCode,
  } = useQuery(
    ['ConventionResultsByCode', conventionParams],
    async () => (graphQLClient && graphQLClient.request(GET_RESULTS_BY_CODE_GQL, conventionParams)),
    {
      staleTime: Infinity,
    },
  );

  const filtersParams = {
    type: 'Convention',
    code: id,
    year,
  };

  const {
    data: dataFilters,
    error: errorFilters,
    isFetching: isFetchingFilters,
  } = useQuery(
    ['ConventionResultsFilters', filtersParams],
    async () => (graphQLClient && graphQLClient.request(
      GET_MY_TEAM_DATA_FILTERS_GQL,
      filtersParams,
    )),
    {
      staleTime: Infinity,
    },
  );

  const conventionParamsExport = {
    ...params,
    numberElements: undefined,
    numberPage: undefined,
  };

  const {
    data: dataResultsByCodeXlsx,
    refetch: refetchResultsByCodeXlsx,
    status: statusResultsByCodeXlsx,
  } = useQuery(
    ['ConventionResultsByCodeXlsx', conventionParamsExport],
    async () => (graphQLClient && graphQLClient.request(
      GET_RESULTS_BY_CODE_GQL,
      conventionParamsExport,
    )),
    {
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    if (statusResultsByCode !== 'success' && (globalParams.numberPage !== undefined
      || globalParams.order !== undefined
      || globalParams.orderType !== undefined)
    ) {
      refetchResultsByCode();
    }

    if (statusResultsByCodeXlsx !== 'success' && (globalParams.numberPage !== undefined
      || globalParams.order !== undefined
      || globalParams.orderType !== undefined)
    ) {
      refetchResultsByCodeXlsx();
    }
  }, [globalParams.numberPage, globalParams.order, globalParams.orderType, categoryResponse]);

  const {
    columns,
    data,
  } = dataResultsByCode && dataResultsByCode.myTeamResultsByCode
  && dataPlanByCode && dataPlanByCode.planByCode
    ? DetailResultsAdapter(id, dataResultsByCode, dataPlanByCode)
    : { columns: [], data: [] };

  const { readyToExport } = useExportStore((state) => ({
    readyToExport: state.readyToExport,
  }), shallow);

  if (errorResultsByCode || errorFilters || errorPlanByCode) {
    return <GenericError />;
  }

  return (
    <Container>
      <ButtonBack onClick={() => navigate(-1)} type="button">Volver</ButtonBack>
      <Typography align="center" color={Theme.colorConvention} display="block" size="28px" margin="2rem auto" weight="500">
        {dataPlanByCode?.planByCode?.description}
      </Typography>

      {!isFetchingFilters ? (
        <FilterForm
          dataFilters={dataFilters}
          filter={globalFilter}
          setFilter={setGlobalFilter}
          type="Convention"
        />
      ) : <FilterFormSkeleton countFilters={10} />}

      {dataResultsByCodeXlsx && (
        <CardDataExport
          id={id}
          data={dataResultsByCodeXlsx}
          dependency={dataPlanByCode}
          fileName={`convention_${id}`}
        />
      )}

      {readyToExport === true && (
        <Loading message="Estamos procesando su fichero. Esto puede tardar unos minutos." tooltip />
      )}

      {!isFetchingResultsByCode ? (
        (dataResultsByCode?.myTeamResultsByCode && dataPlanByCode?.planByCode) ? (
          data && columns.length >= 2 && (
            <Table
              columns={columns}
              data={data}
              theme={Theme.colorConvention}
              countsRecords={dataResultsByCode?.myTeamResultsByCode?.count}
              defaultPageSize={10}
              countPerPage={10}
              globalParams={globalParams}
              setGlobalParams={setGlobalParams}
            />
          )
        ) : (
          <Typography align="center" color={Theme.colorGray} display="block" margin="2rem auto">
            No hay datos
          </Typography>
        )
      ) : (
        <TableSkeleton countCell={6} theme={Theme.colorConvention} />
      )}

    </Container>
  );
}
